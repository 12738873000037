@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&display=swap');
@import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');

a {
    background-color: transparent;
}

.content-wrapper {
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1240px;
}

@media screen and (min-width: 1380px) {
    .content-wrapper {
        padding: 0;
    }
}

.esyncross-header-area {
    z-index: 99;
    position: relative;
    width: 100%;
    /* Specifically for the header navbar */
}

.esyncross-header-area header.esyncross-header {
    position: relative;
}

.esyncross-header-area div.esyncross-header-placeholder {
    height: 0;
}

/* Visibilty Classes */
.hide {
    display: none;
}

.show {
    display: block;
}

.invisible {
    visibility: hidden;
}

.hidden {
    display: none;
    visibility: hidden;
}

/* Anchors */
a {
    color: #313232;
    cursor: pointer;
    text-decoration: underline;
}

a:hover, a:focus {
    text-decoration: underline;
    color: #0DA3C8;
}


/* Lists */
ol {
    margin: 0 0 1.5rem;
    padding: 0 0 0 1.1rem;
}

ul {
    padding: 0 0 0 1.1rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin: 4px 0;
}

ul.no-list {
    list-style: none;
}

/* Horizontal rules */
hr {
    background-color: #F2F2F2;
    color: #D3DBE9;
    border: none;
    height: 1px;
}


.esyncross-header {
    position: fixed;
    z-index: 12;
    width: 100%;
    height: 87px;
    box-shadow: 0px 5px 10px rgba(154, 161, 177, 0.1);
    background-color: rgba(255, 255, 255, 1.0);
}

/* turn off fixed header in page editor */
body.page-editor .esyncross-header,
.preview-iframe-wrapper .esyncross-header,
.hs-inline-edit .esyncross-header {
    position: relative !important;
    top: 0 !important;
}

.esyncross-header-placeholder {
    height: 82px;
}

.esyncross-navbar {
    height: 82px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
    max-width: 1280px !important;
}

.esyncross-navbar__logo {
    width: auto;
    display: block;
    margin-right: 42px;
}

.esyncross-navbar__mobile {
    display: flex;
}

.esyncross-navbar__mobile .hs_cos_wrapper_type_language_switcher {
    display: none;
}

.esyncross-navbar__mobile .hs_cos_wrapper_type_language_switcher + .esyncross-navbar__hamburger {
    margin-left: 32px;
}

.esyncross-navbar__content {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.esyncross-navbar__menu {
    list-style: none;
    display: flex;
    margin-right: auto;
    padding: 0;
    float: left;
}

@media screen and (min-width: 921px) {
    .esyncross-navbar__menu {
        height: 82px;
        align-items: center;
        margin-bottom: 0px !important;
    }
}

.esyncross-navbar__item {
    position: relative;
    margin: 0 32px;
}

.esyncross-navbar__item--active .esyncross-navbar__panel {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

@media screen and (max-width: 920px) {
    .esyncross-navbar__item--active .esyncross-navbar__panel {
        overflow: scroll;
    }
}


@media screen and (min-width: 921px) {
    .esyncross-navbar__item {
        height: 82px;
        display: flex;
        align-items: center;
    }

    .esyncross-navbar__item--active {
        background-color: #F2F2F2;
    }
}

.esyncross-navbar__item--active .esyncross-navbar__button {
    color: #1B3C69 !important;
    text-decoration: none;
}

.esyncross-navbar__item--active .esyncross-navbar__button > span:after {
    visibility: visible;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .esyncross-navbar__item {
        margin: 0 16px;
    }
}

.esyncross-navbar__button {
    font-weight: bold;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    line-height: 2em;
    word-break: normal;
    color: #313232;
    display: inline-block;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.esyncross-navbar__button > span {
    position: relative;
    display: inline-block;
    padding: 8px 12px;
    transition: all .3s ease;
}

.esyncross-navbar__button > span:after {
    content: '';
    width: 0px;
    height: 2px;
    background-color: #1B3C69;
    position: absolute;
    left: 0;
    bottom: -21px;
    visibility: hidden;
    transition: width .2s ease-in-out;
    z-index: 2;
}

.esyncross-navbar__button:hover, .esyncross-navbar__button:focus, .esyncross-navbar__button:active, .esyncross-navbar__button--active {
    text-decoration: none;
    color: #0DA3C8;
}

.esyncross-navbar__button:hover > span:after, .esyncross-navbar__button:focus > span:after, .esyncross-navbar__button:active > span:after, .esyncross-navbar__button--active > span:after {
    visibility: visible;
    width: 100%;
}

.esyncross-navbar__end {
    display: flex;
    align-items: center;
}

.esyncross-navbar__end > span + a {
    margin-left: 25px;
}


.esyncross-navbar__end > a + a, .esyncross-navbar__end > a:not(:first-child) {
    margin-left: 25px;
}

.esyncross-navbar__end > .button_cta:not(:first-child) {
    margin-left: 25px;
}

.esyncross-navbar__panel {
    background-color: rgba(255, 255, 255, 1.0);
    visibility: hidden;
    opacity: 0;
    transform: translateY(8px);
    transition: all .25s ease-in-out;
    position: fixed;
    /* top: 82px; */
    top: 82px; /* top: 118px; */
    left: 0;
    width: 100%;
    border-top: 1px solid #F2F2F2;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
}

.esyncross-navbar__panel-wrapper {
    display: flex;
    flex-wrap: wrap;
    /*max-width: 960px;*/
    max-width: 1280px;
    margin: 0 auto;
    padding: 32px 20px;
    justify-content: space-between;
}

.esyncross-navbar__panel-wrapper > div {
    flex-basis: inherit;
}

.esyncross-navbar__categories {
    display: flex;
    /*justify-content: normal;*/
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 650px;
    flex-grow: 1;
    flex-shrink: 1;
    column-gap: 2px;
}

/*@media only screen and (max-width: 600px) {
.esyncross-navbar__categories {
  display: block;
  justify-content: normal;
  flex-wrap: wrap;
  flex-basis: 650px;
  flex-grow: 1;
  flex-shrink: 1;
  column-gap: 2px;
  height: 100%;
  width: 200px;
  height: 350px;
  overflow: auto;
  }
}*/

.esyncross-navbar__description {
    /*flex-basis: 250px;*/
    flex-basis: 31%;
    margin: 0;
    color: #313232;
}

.subitem-navbar{
    padding:10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    transition: all ease 0.4s;
}
.subitem-navbar:hover{
    background-color: rgba(238, 238, 238, 1);
    transition: all ease 0.4s;
}
@media screen and (min-width: 921px) {
    /*.esyncross-navbar__description:hover {*/
    /*    background-color: rgba(238, 238, 238, 1);*/
    /*    cursor: pointer;*/
    /*}*/
}

.esyncross-navbar__description hr {
    margin: 1rem 0;
}

.esyncross-navbar__description h1, .esyncross-navbar__description h2, .esyncross-navbar__description h3, .esyncross-navbar__description h4, .esyncross-navbar__description h5, .esyncross-navbar__description h6 {
    font-family: 'open sans', sans-serif;
    color: inherit;
}

.esyncross-navbar__description h2 {
    color: #1B3C69;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}

.esyncross-navbar__description h3 {
    font-family: 'open sans', sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 .5rem;
}

.esyncross-navbar__description p {
    font-family: 'open sans', sans-serif;
    font-size: 15px;
    margin: 0 0 1.75rem;
}

.item-title,
.esyncross-navbar__description a {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
}

.esyncross-navbar__description a:hover, .esyncross-navbar__description a:focus {
    text-decoration: none;
    color: #000000;
}

.esyncross-navbar .hamburger {
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: visible;
    min-width: auto;
    margin-top: 7px;
}

.esyncross-navbar .hamburger:focus {
    outline: none;
}

.esyncross-navbar .hamburger-box {
    width: 24px;
    height: 19px;
    display: inline-block;
    position: relative;
}

.esyncross-navbar .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1.5px;
}

.esyncross-navbar .hamburger-inner, .esyncross-navbar .hamburger-inner::before, .esyncross-navbar .hamburger-inner::after {
    width: 24px;
    height: 3px;
    background-color: #313232;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.esyncross-navbar .hamburger-inner::before, .esyncross-navbar .hamburger-inner::after {
    content: "";
    display: block;
}

.esyncross-navbar .hamburger-inner::before {
    top: -8px;
}

.esyncross-navbar .hamburger-inner::after {
    bottom: -8px;
}

.esyncross-navbar .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.esyncross-navbar .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.esyncross-navbar .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.esyncross-navbar .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.esyncross-navbar .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.esyncross-navbar .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Navbar submenu */
.esyncross-navbar-submenu-wrapper {
    position: absolute;
    left: -24px;
    visibility: hidden;
}

.esyncross-navbar__item:hover > .esyncross-navbar-submenu-wrapper {
    visibility: visible;
}

.esyncross-navbar-submenu {
    background-color: rgba(255, 255, 255, 1.0);
    box-shadow: 0px 5px 10px rgba(154, 161, 177, 0.1);
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    width: 160px;
}

.esyncross-navbar-submenu .esyncross-navbar-submenu {
    position: absolute;
    top: 0px;
    left: 100%;
    transform: translateX(0);
    margin: 0;
    visibility: hidden;
}

.esyncross-navbar-submenu__item {
    position: relative;
}

.esyncross-navbar-submenu__item:hover > .esyncross-navbar-submenu {
    visibility: visible;
}

.esyncross-navbar-submenu__item a {
    display: block;
    padding: 12px 24px;
    font-weight: bold;
    font-size: 12px;
    font-family: 'open sans', sans-serif;
    line-height: 1rem;
    word-break: normal;
    color: #313232;
    text-decoration: none;
}

.esyncross-navbar-submenu__item a:hover, .esyncross-navbar-submenu__item a:focus {
    color: #F6F8FC;
    text-decoration: none;
}

.esyncross-navbar__parent {
    cursor: pointer;
}

.esyncross-navbar__parent > a {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}

.esyncross-navbar__parent > a:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    margin-left: 16px;
    border-right: 1px solid #313232;
    border-bottom: 1px solid #313232;
    transform: rotate(45deg);
    transform-origin: 3px 3px;
    transition: transform .25s ease-in-out;
}

.esyncross-navbar__parent--active > .esyncross-navbar-submenu-wrapper,
.esyncross-navbar__parent--active > .esyncross-navbar-submenu {
    display: block;
}

.esyncross-navbar__parent--active > a:after {
    transform: rotate(225deg);
}

.esyncross-navbar__parent--active > a {
    color: #F6F8FC;
}

.esyncross-navbar__parent--active > a > span:after {
    visibility: visible;
    width: 100%;
}

@media screen and (max-width: 920px) {
    .esyncross-navbar {
        justify-content: space-between;
    }

    .esyncross-navbar__panel-wrapper {
        padding: 0 5px;
    }

    .esyncross-navbar--active .esyncross-navbar__content {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    .esyncross-navbar__mobile .hs_cos_wrapper_type_language_switcher {
        display: block;
    }

    .esyncross-navbar__content {
        background-color: rgba(255, 255, 255, 1.0);
        position: fixed;
        width: 100%;
        height: calc(100% - 82px); /* changed on 3.12.2024 from: height: calc(100% - 82px); */
        left: 0;
        bottom: 0;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        visibility: hidden;
        opacity: 0;
        transform: translateY(8px);
        transition: transform .25s ease-in-out, opacity .25s ease-in-out;
        border-top: 1px solid #F2F2F2;
    }

    .esyncross-navbar__menu {
        display: block;
        margin: 0 0 24px;
        width: 100%;
    }

    .esyncross-navbar__item--active .esyncross-navbar__panel {
        max-height: 100vh;
    }

    .esyncross-navbar__button {
        font-size: 1rem;
        /* display: block;*/
    }

    .esyncross-navbar__end > span + a {
        margin-left: 0;
    }

    .esyncross-navbar__end .hs_cos_wrapper_type_language_switcher {
        display: none;
    }

    .esyncross-navbar__panel {
        position: static;
        max-height: 0;
        overflow: hidden;
    }

    .esyncross-navbar__categories p {
        display: none;
        /* flex-basis: 250px;
    margin: 0 16px;
    color: #313232; */
    }

    .esyncross-navbar__description {
        margin: 0;
        flex-basis: 50%;
    }

    .esyncross-navbar__description--full {
        flex-basis: auto;
    }

    .esyncross-navbar__panel {
        box-shadow: none;
    }

    .esyncross-navbar__categories {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .esyncross-navbar .hamburger {
        display: block;
    }

    .esyncross-navbar-submenu-wrapper {
        position: static;
        visibility: visible;
        display: none;
    }

    .esyncross-navbar-submenu {
        box-shadow: none;
        margin: 12px 0;
        width: auto;
    }

    .esyncross-navbar-submenu .esyncross-navbar-submenu {
        position: static;
        visibility: visible;
        display: none;
        margin-left: 20px;
    }

    .esyncross-navbar__button > span:after {
        background-color: transparent;
    }

    .esyncross-navbar-submenu__item a {
        padding: 12px 0;
    }

    .esyncross-navbar__parent:hover > a:after {
        transform: rotate(225deg);
    }

    .esyncross-navbar__parent > a {
        pointer-events: none;
    }

    .esyncross-navbar__parent > a:after {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .esyncross-header-area .hs-search-field__bar form a {
        font-size: 13px !important;
        padding-right: 10px !important;
        margin-bottom: 10px !important;
    }

    .esyncross-header-area .hs-search-field__bar {
        margin: 0 auto;
        text-align: center;
    }
}

@media screen and (max-width: 920px) {
    .esyncross-navbar__content {
        top: 82px;
    }
}

.no-scroll {
    overflow: hidden;
}

/*////////////////////////////////////////////*/
* {
    margin: 0;
    padding: 0
}

body {
    min-height: 100vh
}

.main_nav_items {
    list-style-type: none;
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding: 0
}

.button {
    background: transparent linear-gradient(90deg, #007bb5, transparent) 0 0 no-repeat padding-box;
    background-color: #68d3c5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
    line-height: normal !important;
    margin-left: 15px;
    margin-right: 0 !important;
    padding: 8px 15px;
    transition: all .3s ease
}

.button:hover {
    background-color: #0da3c8
}

.esyncross-navbar__item--active .links {
    transition: all .3s ease
}

.esyncross-navbar__item--active:hover .links {
    transform: rotate(180deg)
}

.esyncross-navbar__item--active .links i {
    transition: all .3s ease
}

.esyncross-navbar__item--active:hover .links i {
    color: #0da3c8
}

.hamburger, .hamburger--squeeze, .hamburger-box, .esyncross-navbar__hamburger {
    background: #fff
}

@media screen and (min-width: 921px) {
    .rcorners {
        border: 2px solid #edf1f8;
        border-radius: 25px
    }
}

.rcorners {
    opacity: 1;
    padding: 20px;
    resize: both;
    width: auto
}

