.breadcumb-menu {
  max-width: 100%;
  margin: -0.35em 0 -0.35em 0;
  padding: 0;
  list-style-type: none;

  li {
    display: inline-block;
    list-style: none;
    position: relative;

    &:after {
      content: '/';      
      position: relative;
      margin: 0 12px 0 10px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }  
    
    // &:last-child {
    //   &,
    //   a,
    //   span {
    //     color: $theme-color;
    //   }
    // }
  }

  li,
  a,
  span {
    white-space: normal;
    color: inherit;
    word-break: break-word;
    font-weight: 500;
    font-size: 16px;
    font-family: $title-font;
    color: $white-color;   
  }
}


.breadcumb-title {
  color: $white-color;
  margin: -0.3em 0 0.05em 0;
  width: 100%;
}


.breadcumb-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 40px;
}

.breadcumb-wrapper {
  padding: 80px 0px 30px 0px;
  position: relative;
  width: 100%;
  z-index: 1;
  min-height: 350px;

  @include md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.breadcumb_headline {
  //color: #fff;
  font: normal 700 15px / 22px Open Sans;
  margin-bottom: 9px;
  text-transform: uppercase;
}
.breadcumb_texts_description, .breadcumb_texts_description p {
  color: #fff;
  font-family: Open Sans, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
  max-width: 1000px;
}
@include md {
  .breadcumb-content {
    display: block;
  }

  .breadcumb-title {
    max-width: 100%;
  }

  .breadcumb-menu {
    margin-top: 15px;
  }
}